import React, { useLayoutEffect } from 'react';
import './sonhos-destaque.component.scss';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';
import { GetSonhosCelebresService, GetSonhosDestaqueService, GetUltimosSonhosService } from '../../services/pages.service';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';
import SonhosModel from '../../models/sonhos.model';
import ComentaiosComponent from '../comentarios/comentarios.component';
import DownloadIcon from '@mui/icons-material/Download';

function SonhosDestaqueComponent() {

  const [listSonhos, setListSonhos] = React.useState<Array<SonhosModel>>([]);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosModel>();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhos();
    }, 500);
  }, []);

  const GetSonhos = () => {
    GetSonhosDestaqueService({ user_id: '9', idsSonhos: [] }).then(res => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListSonhos(listSonhos.concat(res.data.sonhosArr));
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar sonhos na base de dados.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  const ShareIMG = async (img: string) => {
    const blob = await fetch(img).then(r => r.blob())
    const data = {
      files: [
        new File([blob], 'file.jpg', {
          type: blob.type,
        }),
      ],
      //title: title,
      //text: text,
    };
    //navigator.share(data)

    if (navigator.share) {
      try {
        await navigator
          .share(data)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  }

  return (
    <>
      <div className="box-sonhos-destaque">
        <div className="titulo flex flex-sp flex-cv">
          <h3>Sonhos em destaque</h3>
          <Button className='link' onClick={() => GoPage('sonhos-destaque?app=1')}>Ver todos</Button>
        </div>
        <div className="cards-sonhos flex flex-ch">
          {
            listSonhos ? listSonhos.map((item, indexCurso) => (
              <div onClick={() => {
                setSonhoDrawer(item);
                setOpenDrawer(true);
              }} className="card-sonhos flex" key={indexCurso}>
                {
                  item.imagem &&
                  <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                }
                <div className="txt">
                  <strong>Sonho ({item.data}):</strong> {item.content.substring(0, 70)} {item.content.length >= 70 && '...'}
                </div>
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          {
            sonhoDrawer &&
            <>
              <img src={sonhoDrawer?.imagem} alt='Sonho' />
              <Button onClick={() => ShareIMG(sonhoDrawer?.imagem_download)}>
                <DownloadIcon /> Baixar imagem
              </Button>
              <h2>Sonho ({sonhoDrawer?.data}):</h2>
              <div className="desc">
                {sonhoDrawer?.content}
              </div>
              <h3>Interpretação:</h3>
              <div className="desc">
                {sonhoDrawer?.interpretacao}
              </div>
              <div className="box-comentarios">
                <ComentaiosComponent idPost={sonhoDrawer.id} palavrasModeradasADM={['bobo', 'bobão']} />
              </div>
            </>
          }
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SonhosDestaqueComponent;
