import React, { useLayoutEffect, useRef } from 'react';
import { LoadingService } from '../../services/loading.service';
import './home.page.scss';
import { GetEventosService, GetNoticiasService } from '../../services/eventos.service';
import EventosModel from '../../models/eventos.model';
import { SnackService } from '../../services/snack.service';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box, Button, Modal } from '@mui/material';
import VideosModel from '../../models/diarios.model';
import NoticiasModel from '../../models/noticias.model';
import { DestaqueHomeService, PageTermoService } from '../../services/pages.service';
import DestaqueHomeModel from '../../models/destaque-home.model';
import imgiDream from '../../assets/header/logo-idream.svg';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { useHistory } from 'react-router-dom';
import SonhosCelebresComponent from '../../components/sonhos-celebres/sonhos-celebres.component';
import UltimosSonhosComponent from '../../components/ultimos-sonhos/ultimos-sonhos.component';
import SonhosDestaqueComponent from '../../components/sonhos-destaque/sonhos-destaque.component';
import SonhosRealizadosComponent from '../../components/sonhos-realizados/sonhos-realizados.component';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function HomePage() {

  const [destaque, setDestaque] = React.useState<DestaqueHomeModel>();
  const [nBG, setNBG] = React.useState<number>(1);
  const [showMaisTXT, setShowMaisTXT] = React.useState<boolean>(false);
  const ref = useRef<any>();

  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetDestaque();
    }, 500);

    setNBG(Math.floor(Math.random() * (7 - 2 + 1) + 2));
  }, []);

  const GetDestaque = () => {
    LoadingService.sendLoadingAtivo(true);
    DestaqueHomeService().then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setDestaque(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  const getscroll = (refPage: any) => {
    if (refPage.target.scrollTop > 5) {
      document.body.classList.add('is-scroll');
    } else {
      document.body.classList.remove('is-scroll');
    }
  };

  return (
    <>
      <section className={"page home n-" + nBG} ref={ref} onScroll={(scroll) => getscroll(scroll)}>
        <div className="container">
          <p className='box-lua flex flex-ch'>
            <img src={imgiDream} alt="Logo iDream" />
          </p>
          <div className={"box-destaque " + (showMaisTXT && "show")}>
            {/* <div className="img" style={{ backgroundImage: 'url(' + destaque?.imagem + ')' }}></div> */}
            <h2>{destaque?.titulo}</h2>
            <div className='txt' dangerouslySetInnerHTML={{ __html: destaque?.descricao ? destaque.descricao : '' }}></div>
            <div className="box-bt-mais">
              {
                showMaisTXT ?
                  <Button className='recolher' onClick={() => { setShowMaisTXT(false) }}>Recolher</Button>
                  :
                  <Button className='ler-mais' onClick={() => { setShowMaisTXT(true) }}>Ler mais</Button>
              }
            </div>
          </div>
          <SonhosCelebresComponent />
          <UltimosSonhosComponent />
          <SonhosDestaqueComponent />
          <SonhosRealizadosComponent />
          {
            (destaque?.link_ad && destaque?.imagem_ad) &&
            <div className="ads-home">
              <Button href={destaque.link_ad} target='_blank'>
                <img src={destaque.imagem_ad} alt="Ads" />
              </Button>
            </div>
          }
          <div className="box-bts">
            <Button className='bt' onClick={() => GoPage('sonho?app=1')}>Registrar sonho</Button>
            <Button className='bt' onClick={() => GoPage('aconteceu?app=1')}>Registrar acontecimento</Button>
            <Button className='bt insta' href="https://www.instagram.com/idream.a7apps/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon /> Siga nosso instagram
            </Button>
          </div>
          <div className="box-cloud flex flex-ch">
            <FilterDramaIcon />
          </div>
        </div>
      </section>
    </>
  );
}