import Routes from './routes';
import './App.scss';
import IntroPage from './pages/intro/intro.page';

function App() {

  let url = new URL(window.location.href);
  let location: any = url.searchParams.get("location");
  localStorage.setItem('location', location);

  let appURL: any = '';

  if (localStorage.getItem('appURL')) {
    appURL = localStorage.getItem('appURL');
  } else {
    localStorage.setItem('appURL', window.location.href);
    appURL = window.location.href;
  }

  return (
    <>
      {
        (appURL.indexOf('app=1') > -1 || window.location.href.indexOf('termos-e-condicoes-de-uso') > -1) ?
          <Routes />
          :
          <IntroPage />
      }
    </>
  );
}

export default App;
