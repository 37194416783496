
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import SnackComponent from './components/snack/snack.component';
import ProtectedRoute from './protected-route';
import LoginPage from './pages/login/login.page';
import TermosPage from './pages/termos/termos.page';
import LoadingComponent from './components/loading/loading.component';
import BottomNavigationComponent from './components/bottom-navigation/bottom-navigation.component';
import ParceirosPage from './pages/parceiros/parceiros.page';
import TutorialPage from './pages/tutorial/tutorial.page';
import PreferenciaPage from './pages/preferencia/preferencia.page';
import MeuPerfilPage from './pages/meu-perfil/meu-perfil.page';
import LojasPage from './pages/lojas/lojas.page';
import InternaEventosPage from './pages/interna-eventos/interna-eventos.page';
import InternaNoticiasPage from './pages/interna-noticias/interna-noticias.page';
import DiariosPage from './pages/diarios/diarios.page';
import NoticiasPage from './pages/noticias/noticias.page';
import SonhoPage from './pages/sonho/sonho.page';
import AconteceuPage from './pages/aconteceu/aconteceu.page';
import SonhosPage from './pages/sonhos/sonhos.page';
import SonhosCelebresPage from './pages/sonhos-celebres/sonhos-celebres.page';
import UltimosSonhosPage from './pages/ultimos-sonhos/ultimos-sonhos.page';
import SonhosDestaquePage from './pages/sonhos-destaque/sonhos-destaque.page';
import SonhosRealizadosPage from './pages/sonhos-realizados/sonhos-realizados.page';
import ContatoPage from './pages/contato/contato.page';

function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/login" component={LoginPage} />
                <ProtectedRoute
                    exact={true}
                    path={'/home:app'}
                    component={HomePage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/sonhos-celebres:app'}
                    component={SonhosCelebresPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/sonhos-destaque:app'}
                    component={SonhosDestaquePage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/sonhos-realizados:app'}
                    component={SonhosRealizadosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/ultimos-sonhos:app'}
                    component={UltimosSonhosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/diarios:app'}
                    component={DiariosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/noticias:app'}
                    component={NoticiasPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/contato:app'}
                    component={ContatoPage}
                />
                <ProtectedRoute
                    exact={true}
                    path="/evento/:AliasEvento"
                    component={InternaEventosPage}
                />
                <ProtectedRoute
                    exact={true}
                    path="/noticia/:AliasNoticia"
                    component={InternaNoticiasPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/aconteceu:app'}
                    component={AconteceuPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/tutorial'}
                    component={TutorialPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/preferencia'}
                    component={PreferenciaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/meu-perfil:app'}
                    component={MeuPerfilPage}
                />

                <ProtectedRoute
                    exact={true}
                    path={'/lojas'}
                    component={LojasPage}
                />

                <ProtectedRoute
                    exact={true}
                    path={'/sonho:app'}
                    component={SonhoPage}
                />

                <ProtectedRoute
                    exact={true}
                    path={'/lista-sonhos:app'}
                    component={SonhosPage}
                />
                <Route exact path="/termos-e-condicoes-de-uso" component={TermosPage} />

                <Route component={NotFoundPage} />
            </Switch>
            <SnackComponent />
            <LoadingComponent />
            <BottomNavigationComponent />
        </BrowserRouter>
    )
}

export default Routes;