import React, { useLayoutEffect } from 'react';
import './sonhos-destaque.page.scss';
import { LoadingService } from '../../services/loading.service';
import { GetEventosService } from '../../services/eventos.service';
import { SnackService } from '../../services/snack.service';
import EventosModel from '../../models/eventos.model';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useHistory } from 'react-router-dom';
import { Box, Button, SwipeableDrawer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { GetSonhosCelebresService, GetSonhosDestaqueService } from '../../services/pages.service';
import SonhosCelebresModel from '../../models/sonhos-celebres.model';
import SonhosModel from '../../models/sonhos.model';
import ComentaiosComponent from '../../components/comentarios/comentarios.component';
import DownloadIcon from '@mui/icons-material/Download';

function SonhosDestaquePage() {

  const [listIDs, setListIDs] = React.useState<Array<number>>([]);
  const [listSonhosCelebres, setListSonhosCelebres] = React.useState<Array<SonhosModel>>([]);
  const [acabouListSonhosCelebres, setAcabouListSonhosCelebres] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [sonhoDrawer, setSonhoDrawer] = React.useState<SonhosModel>();
  let history = useHistory();

  useLayoutEffect(() => {
    setTimeout(() => {
      GetSonhosCelebres();
    }, 500);
  }, []);

  const GetSonhosCelebres = () => {
    LoadingService.sendLoadingAtivo(true);
    GetSonhosDestaqueService({ user_id: '7', idsSonhos: listIDs }).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListIDs(listIDs.concat(res.data.idsArr));
        setListSonhosCelebres(listSonhosCelebres.concat(res.data.sonhosArr));
        if (res.data.sonhosArr.length < 5) {
          setAcabouListSonhosCelebres(true);
        }
      }
    }).catch(err => {
      //SnackService.sendMSG('Erro com a conexão ao consultar eventos na base de dados.');
      LoadingService.sendLoadingAtivo(false);
      console.log(err);
    });
  }

  const ShareIMG = async (img: string) => {
    const blob = await fetch(img).then(r => r.blob())
    const data = {
      files: [
        new File([blob], 'file.jpg', {
          type: blob.type,
        }),
      ],
      //title: title,
      //text: text,
    };
    //navigator.share(data)

    if (navigator.share) {
      try {
        await navigator
          .share(data)
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  }

  return (
    <>
      <section className="page sonhos-destaque">
        <div className="head">
          <div className="container">
            <div className="flex flex-cv flex-sp">

            </div>
          </div>
        </div>
        <div className="container">
          <div className="cards-sonhos-celebres flex flex-ch flex-wr">
            {
              listSonhosCelebres ? listSonhosCelebres.map((item, indexCurso) => (
                <div onClick={() => {
                  setSonhoDrawer(item);
                  setOpenDrawer(true);
                }} className="card-sonhos-celebres flex" key={indexCurso}>
                  {
                    item.imagem &&
                    <div className="img" style={{ backgroundImage: 'url(' + item.imagem + ')' }}></div>
                  }
                  <div className="txt">
                    <strong>Sonho ({item.data}):</strong> {item.content.substring(0, 70)} {item.content.length >= 70 && '...'}
                  </div>
                </div>
              )) : <span>Nenhum item encontrado.</span>
            }
          </div>
          {
            (!acabouListSonhosCelebres && listSonhosCelebres.length >= 5) &&
            <div className="box-bt-mais flex flex-ch">
              <Button className='bt' onClick={GetSonhosCelebres}>
                <AddIcon />
                sonhos...
              </Button>
            </div>
          }
        </div>
      </section>
      <SwipeableDrawer
        anchor='bottom'
        open={openDrawer}
        onClose={() => { setOpenDrawer(false) }}
        onOpen={() => { setOpenDrawer(true) }}
        className='sonhos-drawer'
      >
        <Box
          sx={{ height: '90vh', width: '100%' }}
          role="presentation"
        // onClick={() => { setOpenDrawer(false) }}
        //onKeyDown={() => { setOpenDrawer(false) }}
        >
          {
            sonhoDrawer &&
            <>
              <img src={sonhoDrawer?.imagem} alt='Sonho' />
              <Button onClick={() => ShareIMG(sonhoDrawer?.imagem_download)}>
                <DownloadIcon /> Baixar imagem
              </Button>
              <h2>Sonho ({sonhoDrawer?.data}):</h2>
              <div className="desc">
                {sonhoDrawer?.content}
              </div>
              <h3>Interpretação:</h3>
              <div className="desc">
                {sonhoDrawer?.interpretacao}
              </div>
              <div className="box-comentarios">
                {
                  sonhoDrawer &&
                  <ComentaiosComponent idPost={sonhoDrawer.id} palavrasModeradasADM={['bobo', 'bobão']} />
                }
              </div>
            </>
          }
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default SonhosDestaquePage;
